export const parseSearch = (search: string) => {
  if (search) {
    const obj: any = {}
    const parseArr = search.substring(1)?.split('&')
    parseArr.forEach((item: string) => {
      const parseItem = item?.split('=')
      obj[parseItem[0]] = parseItem[1]
    })
    return obj
  }
  return {}
}


// export  const verify = (password: string) => {
//   if (!password) {
//     return '请输入密码'
//   }
//   if (/^[a-zA-Z]+$/.test(password)) {
//     return '不能为全字母'
//   }
//   if (/^[0-9]+$/.test(password)) {
//     return '不能为全数字'
//   }
//   if (/^[^0-9a-zA-Z]+$/.test(password)) {
//     return '不能为全字符'
//   }
//   const length = password.length
//   if (length < 8 || length > 20) {
//     return '长度在 8-20 之间'
//   }
//   return null
// }