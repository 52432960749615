import React, { memo, useCallback, useMemo, useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import { Popover } from 'antd';
import { AnimationScrollTop } from '@/utils/AnimationScrollTop'
import GEN_API from '@/api/fetch'
import './style.scss'


const Header = observer(({ ssoToken, setSsoToken, checkLan, lan, setIsGmail, isGmail }: any) => {
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [show, setShow] = useState<boolean>(localStorage?.ssoToken || '')
  const [exchange, setExchange] = useState<string>('')
  const [isShow, setIsShow] = useState<boolean>(true)

  // 判断注册与登录按钮是都显示
  useEffect(() => {
    if (!localStorage?.ssoToken) {
      setShow(ssoToken)
    } else {
      setShow(localStorage?.ssoToken)
    }
  }, [ssoToken])

  // 语言设置
  const locales = useMemo(() => [
    {
      locale: 'zh_CN',
      localeText: '简体中文'
    },
    {
      locale: 'en-US',
      localeText: 'English'
    }
  ], [])

  // 导航栏
  const navigates = useMemo(() => [
    {
      label: lan.home,
      path: '/',
      show: true,
      class: ""
    },
    {
      label: lan.product,
      path: '/',
      show: true,
      class: ""
    },
    {
      label: lan.solution,
      path: '/',
      show: true,
      class: ""
    },
    {
      label: lan.walletAPI,
      path: 'https://documenter.getpostman.com/view/6659127/U16eunu7',
      show: true,
      class: ""
    },
    {
      label: lan.login,
      path: '/login',
      // show: (localStorage?.ssoToken || ssoToken) ? false : true,
      show: show ? false : true,
      class: "login"
    }
  ], [lan, show])

  // 点击滑动到指定的区域
  const move = useCallback((type: any) => {
    switch (type) {
      case lan.product:
        const box: any = document.getElementsByClassName('product-canvas')[0]
        const top = box.offsetTop
        new AnimationScrollTop({ x: 0, y: top })
        break;
      case lan.solution:
        const box1: any = document.getElementsByClassName('solution-box')[0]
        const top1 = box1.offsetTop
        new AnimationScrollTop({ x: 0, y: top1 })
        break;
      default:
        break
    }
  }, [lan])

  const scrollTop = (documentObj: any) => {
    return window.pageYOffset || documentObj.scrollTop || 0
  }

  // 滚动条控制头部到的显示与否
  useEffect(() => {
    const documentObj = document.documentElement || document.body
    let init_top = scrollTop(documentObj)
    window.onscroll = function () {
      const scroll_top = scrollTop(documentObj);
      if (scroll_top >= init_top) { // 说明在下滑， 隐藏导航栏
        setIsShow(false)
        init_top = scroll_top
      } else {
        setIsShow(true)
        init_top = scroll_top
      }
    }
  }, [])

  // 获取登录账号信息
  const emailByParse = useCallback(async () => {
    const res = await GEN_API.applyTicket({ application: "gow" }) // 验证票据  parseTicket
    if (res?.data?.ticket && !email) {
      const { data } = await GEN_API.parseTicket({ ticket: res?.data?.ticket, application: "gow" })
      setEmail(data?.email || '')
    }
  }, [email])
  useEffect(() => {
    if (!localStorage?.isGmail) {
      setExchange(isGmail)
    } else {
      setExchange(localStorage?.isGmail)
    }
  }, [ssoToken, isGmail])

  // 解析验证获取邮箱账号
  useEffect(() => {
    if (show && !email) {
      emailByParse()
    }
  }, [show, email, emailByParse])

  // 退出登录
  const logout = useCallback(() => {
    setSsoToken('')
    setIsGmail(false)
    setEmail('')
    setShow(false)
  }, [setSsoToken, setIsGmail])

  return (
    <div className={`header ${isShow ? '' : 'none'}`}>
      <div className="nav-left">
        <Link to='/'> <img className="logo" src={require('@/assets/images/logo.png')} alt="" /></Link>
      </div>
      <div className="nav-right">
        <ul id="container">
          {
            navigates.map((item: any) => {
              return (
                item?.show && (item?.path.includes('http') ?
                  <div key={item?.label}>
                    {
                      item?.label === 'GitHub' ? <li key={item?.label}>
                        <a href={item?.path} target="_blank" rel="noopener noreferrer">
                          <img src={item?.img} className="head-git-icon" alt="" />
                        </a>
                      </li> : <li key={item?.label}><a href={item?.path} target="_blank" rel="noopener noreferrer">{item?.label}</a></li>
                    }
                  </div> :
                  <li
                    id="a3"
                    key={item?.label}
                    className={item?.path === '/login' ? 'login-btn' : ''}
                  ><Link to={item?.path} onClick={() => move(item?.label)}>{item?.label}</Link></li>
                )
              )
            })
          }
          {/* github */}
          <li>
            <a href='https://github.com/metaplasiateam' className="head-git-icon"  target="_blank" rel="noopener noreferrer">
              <img src={require('@/assets/images/head-git.svg')}  alt="" />
              <img src={require('@/assets/images/head-git-hover.svg')} alt="" />
            </a>
          </li>


          {
            show ? <Popover
              placement="bottom"
              trigger="click"
              content={
                <ul className="popover-list">
                  {(exchange !== 'false') ? <li onClick={() => history.push('/login')}> <img src={require('@/assets/images/exchange.svg')} alt="" />{lan.switchAccount}</li> : <></>}
                  <li> <img src={require('@/assets/images/personal.svg')} alt="" />{lan.personalCenter}</li>
                  <li onClick={() => logout()}> <img src={require('@/assets/images/logout.svg')} alt="" />{lan.logOut} </li>
                </ul>
              }
              overlayClassName="ant-popover-user"
            >
              <div className="current-info">{email}</div>
            </Popover> : <></>
          }
          <Popover
            placement="bottom"
            trigger="click"
            content={
              <ul className="popover-list">
                {
                  locales.map(({ locale, localeText }: any) => {
                    return (
                      <li
                        key={locale}
                        onClick={() => checkLan(locale)}
                      >{localeText}
                      </li>
                    )
                  })
                }
              </ul>
            }
            overlayClassName="ant-popover-lan"
          >
            <div className="language-icon">
              <img className="logo" src={require('@/assets/images/switch-language.svg')} alt="" />
              <img className="logo" src={require('@/assets/images/switch-language-hover.svg')} alt="" />
            </div>
          </Popover>
        </ul>
      </div>
    </div>
  )

})


const mapStateToProps = ({ appStore: {
  ssoToken,
  setSsoToken,
  setIsGmail,
  isGmail,
  checkLan,
  lan
} }: any) => {
  return {
    ssoToken,
    setSsoToken,
    setIsGmail,
    isGmail,
    checkLan,
    lan: { ...lan.home },
  }
}

export default inject(mapStateToProps)(memo(Header));