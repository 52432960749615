import React, { memo, useCallback, useRef } from 'react'
import { Form, Input, Button, message } from 'antd';
import { useHistory, withRouter } from 'react-router-dom'
import { useToggle } from '@/hooks'
import APP_API from '@/api/fetch'
import '../style.scss'


const Retrieve: React.FC<any> = memo(({ email_key, location:{pathname}, lan }) => {
  const scroll: any = useRef()
  const [form] = Form.useForm();
  const submitToggle = useToggle() // 提交按钮
  const history = useHistory()

  const onFinish = useCallback(async(values: any) => {
    submitToggle.update()
    if((values.password !== values.confirmPassword)){
      return message.error(lan.passwordError)
    }
    try {
      const params = {
        password: values.password,
        email_key: email_key,
      }
      await APP_API.retrievePassword(params)
      message.success(lan.passwordSuccess)
      history.push('/login')
    } catch (err: any) {
      message.error(err?.message)
    } finally {
      submitToggle.update()
    }
  }, [submitToggle, email_key, history, lan])

  const validateLoginPassword = useCallback((password: string) => {
    if (!password) {
      return lan.passwordInput
    }
    if (/^[a-zA-Z]+$/.test(password)) {
      return lan.passwordWarn1
    }
    if (/^[0-9]+$/.test(password)) {
      return lan.passwordWarn3
    }
    if (/^[^0-9a-zA-Z]+$/.test(password)) {
      return lan.passwordWarn2
    }
    const length = password.length
    if (length < 8 || length > 20) {
      return lan.passwordWarn4
    }
    return null
  }, [lan])


  return (
    <div className="user" ref={scroll}>
      <div className="title">{lan.retrievePassword}</div>
      <Form
        form={form}
        name="register"
        className="user-form"
        onFinish={onFinish}
        scrollToFirstError
      >
        <>
          <div className="form-item-name">{lan.enterPassword}</div>
          <Form.Item
            name="password"
            rules={[
              () => ({
                validator(_, value) {
                  if (validateLoginPassword(value) === null) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(validateLoginPassword(value) || ''));
                },
              }),
            ]}
          >
            <Input.Password placeholder={lan.passwordWarn} />
          </Form.Item>
        </>

        <>
          <div className="form-item-name">确认密码</div>
          <Form.Item
            name="confirmPassword"
            rules={[
              () => ({
                validator(_, value) {
                  if (validateLoginPassword(value) === null) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(validateLoginPassword(value) || ''));
                },
              }),
            ]}
          >
            <Input.Password placeholder={lan.passwordWarn} />
          </Form.Item>
        </>
         <Button loading={submitToggle.current} htmlType="submit" className="regs-btn"> {lan.retrievePassword}</Button>
      </Form>
    </div>
  )
})

export default withRouter(Retrieve)