import React, { memo, useLayoutEffect } from 'react';
import { HashRouter as Router, Switch, Route, RouteProps } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import { Header, Footer } from '@/components'
import routes from '@/routes'
import './App.scss';

const App: React.FC<any> = observer(({ lan, checkLan }) => {

  useLayoutEffect(() => {
    if (localStorage.language) {
      checkLan(localStorage.language)
    } else {
      if (navigator.language?.includes('zh_CN')) {
        checkLan('zh_CN')
      } else {
        checkLan('en-US')
      }
    }
  }, [checkLan, lan])

  return (
    <Router>
      <Header />
      <Switch>
        {
          routes.map((route: RouteProps) => {
            return (
              <Route
                key={route.path as string}
                {...route}
                exact />
            )
          })
        }
      </Switch>
      <Footer />
    </Router>
  );
})

const mapStateToProps = ({ appStore: {
  lan,
  checkLan
} }: any) => {
  return {
    lan,
    checkLan
  }
}

export default inject(mapStateToProps)(memo(App))