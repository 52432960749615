// eslint-disable-next-line import/no-anonymous-default-export
export default {
  locale: 'zh_CN',
  home:{
    home:"首页",
    register:"注册",
    login:"登录",
    switchAccount:"切换账号",
    personalCenter:"个人中心",
    logOut:"登出",
    product:"产品",
    walletAPI:"钱包API ",
    service1:"一站式区块链",
    service2:"通往元宇宙的整体方案服务商",
    toBService:"专业的 To B 服务商，赋能元宇宙世界，链接元宇宙",
    regisText:"立即注册，免费接入",
    metaverse:"元宇宙是一个平行于现实世界，又独立于现实世界的虚拟空间",
    sdk:"实现标准化SDK ",
    features:"多链/便捷/去中心化/多功能，SDK完美接入一站式解决你的需求，即时接入即时可用",
    integrated:"集成钱包/交易所/策略等",
    code:"(代码可开源)",
    integText:"无需自己开发交易所/钱包/交易策略等，使用现成的SDK接入，支持私有化部署，玩转区块链，可专属定制各类功能 ",
    gateway:"通往元宇宙之门",
    gatewayText:"开发者可以使用API操作和PushAction等，无需逐个接入应用商，节省大量的人力和时间成本 ",
    solution:"解决方案",
    joinMetaverse:"快速参与元宇宙，节省大量开发时间周期",
    ourProducts:"我们的产品 ",
    merit:"多元化，满足不同需求",
    support1:"支持多链",
    support1Text:"(ERC20/Omni/TRC20/BEP20/Heco/ERC721/EOS等),近500个币种无需自己维护及搭建节点，无需自己开发智能合约，使用SDK一键接入即可",
    support2:"支持多交易所聚合交易",
    support2Text:"Binance/OKX/Gate/MXC/Huobi/FTX/Uniswap/Curve等去中心化交易平台。实现 CeFi 和 DeFI 相结合。快速撮合，无需担忧深度和滑点问题。一站接入全部聚合",
    support3:"策略交易 ",
    support3Text:"套利型策略：三角套利、期现套利、费率套利；<br/>CTA 策略：双均线策略、指数增强、海龟交易、趋势轮动；<br/>市场中性策略：网格交易、市商策略、平衡策略",
    support4:"更多产品",
    support4Text:"更多的产品线正在支持中，金融衍生品/NFT发行交易收藏/合约交易/元宇宙相关开发等",
    case:"客户案例",
    gateOfMetaverse:'开启元宇宙之门',
    regisAndInte:"立即注册接入",
    ways:"支持多种服务方式",
    efficiency:"项目上线效率提升",
    team:"团队效能提升",
    response:"响应速度提升",
    codeSafety:"代码安全提升",
    product1:"钱包支付业务",
    product2:"聚合交易所API",
    product3:"量化交易",
    product4:"各类定制服务",
    helpFile:"帮助文档",
    helpFile1:"提交工单",
    helpFile2:"客服邮箱",
    helpFile3:"一站式区块链通往元宇宙的整体方案服务商",
    willOnline:"产品即将上线"
  }, 
  login:{
    login1:"账号登录",
    Register1:"账号注册",
    emailAdress:"邮箱地址",
    ePlaceholder: '请输入邮箱地址',
    enterPassword:"输入密码", 
    emailTip: '邮箱格式不正确，请重新输入',
    password: '密码',
    register:"注册新账号",
    login2:"登录",
    register1:"注册",
    accountTips:'已有账号，跳转至',
    forgotPassword:"忘记密码？",
    loginPage:"登录页面",
    passwordWarn1: '不能为全字母',
    passwordWarn2: '不能为全字符',
    passwordWarn3: '不能为全数字',
    passwordWarn4: '长度在 8-20 之间',
    retrievePassword:"找回密码",
    next: '下一步',
    loginSuccessTips:"登录成功",
    regisSuccessTips:"注册完成，将进入登录页面", 
    resetSuccessTips:"重设密码的邮件已发送成功，至邮箱进行查看！",
    passwordError:"两者输入的密码不一致，请重新输入",
    passwordSuccess:"修改密码成功， 将为你跳转到登陆页面",
    passwordWarn: '密码由8—20位字符组成，不可以是纯数字',
  }
}