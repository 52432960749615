import axios from 'axios'
import { BASE_URL } from '@/envconfig'

class Instance {
  private instance: any
  private headers: any
  protected source: any
  constructor(baseURL?: string) {
    this.source = axios.CancelToken.source()
    this.headers = {}
    this.instance = axios.create({
      baseURL: baseURL || BASE_URL,
      timeout: 30000,
      withCredentials: false
    })

    // 请求拦截器
    this.instance.interceptors.request.use(config => {
      const token = localStorage?.ssoToken 
      const Authorization = 'Authorization'
      if (token) {
        config.headers[Authorization] = `Bearer ${token}`
      }
      return config
    }, (error: any) => {
      return Promise.reject(error);
    })
  }

  protected axios<T>(method: string, url: string, config: object = {}): Promise<T> {
    this.reset()
    this.headers['Accept-Language'] = localStorage.language === 'zh_CN' ? 'zh_CN' : 'en-US' || 'en-US'

    return this.instance.request({
      method,
      url,
      cancelToken: this.source.token,
      headers: this.headers,
      ...config
    })
      .then((res: any) => {
        return Promise.resolve(res.data)
      }, (err: any) => {
        console.log(err)
        return Promise.reject(err.response || err)
      })
  }

  protected reset() {
    this.source = axios.CancelToken.source()
  }

  public cancel() {
    this.source.cancel('CANCEL')
  }
}


class API extends Instance {

  async get<T = any>(url: string, params?: object) {
    try {
      return await this.axios<T>('GET', url, { params })
    } catch (err: any) {
      throw err.data
    }
  }

  async post<T = any>(url: string, data?: object) {
    try {
      return await this.axios<T>('POST', url, { data })
    } catch (err: any) {
      throw err.data
    }
  }

  async put<T = any>(url: string, data?: object) {
    try {
      return await this.axios<T>('PUT', url, { data })
    } catch (err: any) {
      throw err.data
    }
  }

  async delete<T = any>(url: string, data?: object) {
    try {
      return await this.axios<T>('DELETE', url, { data })
    } catch (err: any) {
      throw err.data
    }
  }

}

export default API