import { observable, action } from 'mobx'
import enUS from '@/assets/locales/en_US'
import zhCN from '@/assets/locales/zh_CN'

class appStore {
  @observable ssoToken?: string = ''
  // @observable ssoTicket?: string = ''
  @observable isGmail?: string = ''
  @observable lan: any = zhCN

  // 保存用户的token信息
  @action
  setSsoToken = (token?: any) => {
    this.ssoToken = token
    localStorage.ssoToken = token
  }
// 保存用户的ticket信息
  @action
  setIsGmail= (gmail?: any) => {
    this.isGmail = gmail
    localStorage.isGmail = gmail
  }

  @action
  checkLan = (locale: string) => {
    localStorage.language = locale
    switch (locale) {
      case 'zh_CN':
        this.lan = zhCN
        break;
      case 'en-US':
        this.lan = enUS
        break;
      default:
        break
    }
  }

}
export default new appStore()