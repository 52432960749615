import React, { memo, useState, useEffect, useCallback } from "react";
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom'
import { Core, Solution, Product, Customer } from './components'
import VisibilityObserver from 'react-visibility-observer'
import Lottie from 'react-lottie';
import animationData from '@/assets/images/plant.json'
import './style.scss'

const Home: React.FC<any> = observer(({ lan, ssoToken }) => {

  const history = useHistory()
  const [isLogin, setIsLogin] = useState<boolean>(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const goRegisPage = useCallback(() => {
    // 没有登录的情况下才会进行页面跳转
    if (!isLogin) {
      history.push('/register')
    }
  }, [history, isLogin])

  useEffect(() => {
    if (localStorage?.ssoToken && ssoToken) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [ssoToken])

  return (
    <div className="home">
      <div className="box-top">
        <Core lan={lan} isLogin={isLogin} goRegisPage={goRegisPage} />
        <Solution lan={lan} />
        <img className="semicircle" src={require('@/assets/images/semicircle.png')} alt="" />
        <div className="circular">
          <Lottie options={defaultOptions} />
        </div>
        <img className="line" src={require('@/assets/images/left-line.png')} alt="" />
      </div>
      <VisibilityObserver
        rootMargin="50px 50px 50px 50px"
        threshold={0.2}
      >
        <Product lan={lan} />
      </VisibilityObserver>
      {/* <Serve lan={lan}/> */}
      <Customer lan={lan} isLogin={isLogin} goRegisPage={goRegisPage} />
    </div>
  )

})



const mapStateToProps = ({ appStore: {
  lan,
  ssoToken
} }: any) => {
  return {
    lan: {
      locale: lan.locale,
      ...lan.home
    },
    ssoToken
  }
}
export default inject(mapStateToProps)(memo(Home))