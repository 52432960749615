import React, { memo, useMemo } from "react";
import './style.scss'

const Customer: React.FC<any> = memo(({lan, isLogin, goRegisPage}:any) => {
  const customerList = useMemo(() => [
    {
      logo: require('@/assets/images/customer/gow.png'),
      name: 'https://gow.one/'
    },
    {
      logo: require('@/assets/images/customer/nft.jpg'),
      name: 'https://castablenft.com/'
    },
    {
      logo: require('@/assets/images/customer/pizza.png'),
      name: 'https://pizza.live/'
    },
    {
      logo: require('@/assets/images/customer/yebe.png'),
      name: ''
    },
    {
      logo: require('@/assets/images/customer/lighthouse.png'),
      name: 'https://www.beaconglobal.top/#/'
    },
    {
      logo: require('@/assets/images/customer/morecoin.png'),
      name: '',
    },
    {
      logo: require('@/assets/images/customer/bit2p.png'),
      name: ''
    },
    {
      logo: require('@/assets/images/customer/more.png'),
      name: ''
    },


  ], [])

  const efficientList = useMemo(() => [
    {
      efficient: '185%',
      text: lan.efficiency
    },
    {
      efficient: '123%',
      text: lan.team
    },
    {
      efficient: '162%',
      text: lan.response
    },
    {
      efficient: '336%',
      text: lan.codeSafety
    },
  ], [lan])

  return (
    <div className="customer-box">
      <div className="customer">
        <div className="title"> {lan.case} </div>
        <div className="box">
          {
            customerList.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {
                    item?.name ? <a key={index} href={item.name} target="_blank" rel="noopener noreferrer">
                      <img src={item.logo} alt="" />
                    </a> : <div key={index}><img src={item.logo} alt="" /></div>

                  }
                </div>
              )
            })
          }
        </div>
      </div>
      {/*元宇宙之门 */}
      <div className="universe">
        <div className="title-btn">
          <div className="title">Metaplasia {lan.gateOfMetaverse}</div>
          <div className={`btn ${!isLogin ? '' :'disable'}`} onClick={()=>goRegisPage()}>{isLogin ? lan.willOnline :lan.regisAndInte}</div>
        </div>
        <img className='universe-img' src={require(`@/assets/images/cosmicGate.png`)} alt='' />
      </div>
      <ul>
        {
          efficientList.map(({ efficient, text }: any) => {
            return <li key={text}>
              <p>
                <span>{efficient}</span>
                <img src={require('@/assets/images/up-icon.svg')} alt="" />
              </p>
              <p>{text}</p>
            </li>
          })
        }
      </ul>
    </div>
  )

})


export default Customer