import React, { memo } from 'react'
import UserForm from './UserForm'
import './style.scss'

const Login: React.FC<any> = memo(() => {

  return (
    <div className="login">
      <UserForm type="login"/>
    </div>
  )
})

export default Login