import React, { memo } from 'react'
import UserForm from './UserForm'
import './style.scss'

const Register: React.FC<any> = memo(() => {

  return (
    <div className="register">
      <UserForm type="registered"/>
    </div>
  )
})

export default Register