import React, { memo, useCallback, useRef } from 'react'
import { parseSearch } from '@/utils'
import { Form, Input, Button, message } from 'antd';
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import { useToggle } from '@/hooks'
import APP_API from '@/api/fetch'
import './style.scss'

const ForPassword: React.FC<{}> = observer(({ lan, location: { search }}: any) => {
  const scroll: any = useRef()
  const [form] = Form.useForm();
  const submitToggle = useToggle() // 提交按钮

  const onFinish = useCallback(async(values: any) => {
    submitToggle.update()
    try {
      const params = {
        email: values.email,
      }
      await APP_API.forgetPassword(params)
      message.success(lan.resetSuccessTips)

    } catch (err: any) {
      message.error(err?.message)
    } finally {
      submitToggle.update()
    }
  }, [submitToggle, lan])

  return (
    <div className="forgot">
      {
        parseSearch(search)?.email_key ? <>zhaomima </>: <div className="user" ref={scroll}>
        <div className="title">{lan.retrievePassword}</div>
        <Form
          form={form}
          name="register"
          className="user-form"
          onFinish={onFinish}
          scrollToFirstError
        >

          <div className="form-item-name">{lan.emailAdress}</div>
          <Form.Item
            className="phone"
            name="email"
            rules={[
              { required: true, message: lan.ePlaceholder },
              {
                // eslint-disable-next-line no-useless-escape
                pattern: /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/,
                message: lan.emailTip
              }
            ]}
          >
            <Input placeholder={lan.ePlaceholder} autoComplete="off" />
          </Form.Item>
          <Button loading={submitToggle.current} htmlType="submit" className="regs-btn">{lan.next}</Button>

        </Form>

      </div>
      }
    </div>
  )
})

const mapStateToProps = ({ appStore: {
  setSsoToken,
  lan
} }: any) => {
  return {
    setSsoToken,
    lan: { ...lan.login }
  }
}

export default withRouter(inject(mapStateToProps)(memo(ForPassword)))