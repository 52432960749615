// eslint-disable-next-line import/no-anonymous-default-export
export default {
  locale: 'en-US',
  home:{
    home:"Home",
    register:"Register",
    login:"Login",
    switchAccount:"Switch account",
    personalCenter:"Personal center",
    logOut: 'Disconnect',
    product:"Product",
    walletAPI:"Wallet API",
    service1:"All-in-one blockchain",
    service2:"Full solution provider<br/>towards metaverse",
    toBService:"Professional to B provider, enable and link metaverse",
    regisText:"Register, free integration",
    metaverse:" The metaverse is a virtual space parallel to and independent from the real world",
    sdk:"Unify standardlize SDK",
    features:"Multi-chain / Convenience / Decentralized / Multi-function, satisfy your needs with perfect SDK integration in one stop, integrate to use instantly",
    integrated:"Integrate with wallet / Exchange / Strategy, etc",
    code:"(Code can open sourc)",
    integText:"Unnecessary to develop exchange/ Wallet/ Trading strategy yourselves, integrate the ready-to-go SDK, supports private deployment, explore blockchain, exclusive fucntion supported",
    gateway:"Toward the gate of metaverse",
    gatewayText:"Developers can use API operation and PushAction without integrate applications one by one, save power and time mostly",
    solution:"Solution",
    joinMetaverse:"Join metaverse instantly, save development period",
    ourProducts:"Our products",
    merit:"Diversification to meet different needs",
    support1:"Supports multi-chains",
    support1Text:"(ERC20/Omni/TRC20/BEP20/Heco/ERC721/EOS and so on), nearly 500 different tokens, no need to build and maintain node, no need to develop smart contract yourselves, one key to integrate SDK",
    support2:" Support aggregated trading with multi exchanges",
    support2Text:"(Binance / OKX / Gate / MXC / Huobi / FTX / Uniswap / Curve and other decentralized trading platforms). Combines CeFi and DeFi, match orders instantly, no worry about depth and slippage. One stop to integrate all aggregation",
    support3:"Strategy trading",
    support3Text:"Arbitrage Strategy：Triangular Arbitrage、Spot-Futures Arbitrage、Funding-Rate Arbitrage<br/> CTA Strategy：Double Moving Average、Enhanced Index、Turtle Trading、Trend Rotation<br/> Market Neutral Strategy：Grid Trading、Market Maker Strategy、Equilibrium Strategy",
    support4:"More products",
    support4Text:"More products are under development, financial derivative / NFT issuance, trade, collection / contract trading / related development of metaverse",
    case:"Case",
    gateOfMetaverse:'Open the gate of metaverse',
    regisAndInte:"Register and integrate instantly",
    ways:"Support a variety of service methods",
    efficiency:"Project launch efficiency",
    team:"Team effectiveness improvement",
    response:"Response speed improvement",
    codeSafety:"Code security improvement",
    product1:"Wallet payment business",
    product2:"Aggregated exchange API",
    product3:"Quantitative trading",
    product4:"Custom Service",
    helpFile:"Help file",
    helpFile1:"Sumit order",
    helpFile2:"Service email",
    helpFile3:"All-in-one blockchain full solution provider towards metaverse",
    willOnline:"Product coming soon"
  },
  login:{
    login1:"Login",
    Register1:"account registration",
    emailAdress:"Email address",
    ePlaceholder: 'Please enter the email address',
    enterPassword:"Enter password", 
    emailTip: 'Incorrect email address format, enter again',
    password: 'Password',
    passwordWarn: 'Password should consist 8 – 20 characters and cannot be only numbers.',
    forgotPassword:"Forgot password？",
    register:"Register",
    login2:"Login in",
    register1:"Register",
    accountTips:'If you already have an account, go to ',
    loginPage:"the login page",
    passwordWarn1: 'Password cannot be all in letters',
    passwordWarn2: 'Password cannot be all in symbols',
    passwordWarn3: 'Password cannot be all in numbers',
    passwordWarn4: 'Length should be between 8-20 characters',
    retrievePassword:"Retrieve password",
    next: 'Next step',
    loginSuccessTips:"Login successful",
    regisSuccessTips:"After the registration is completed, you will enter the login page",
    resetSuccessTips:"The email to reset the password has been sent successfully,To the mailbox to check!",
    passwordError:"The entered passwords do not match, please re-enter",
    passwordSuccess:"Successfully changing the password, you will be redirected to the login page",
  }
}