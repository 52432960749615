import React, { memo, useCallback, useRef } from 'react'
import { Form, Input, Button, message } from 'antd';
import { Link, useHistory, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import { useToggle } from '@/hooks'
import GoogleLogin from './GoogleLogin'
import APP_API from '@/api/fetch'
import '../style.scss'


const UserForm: React.FC<any> = observer(({ type, location: { pathname }, setSsoToken, setIsGmail, lan }) => {
  const scroll: any = useRef()
  const [form] = Form.useForm();
  const submitToggle = useToggle() // 提交按钮
  const history = useHistory()

  const validateLoginPassword = useCallback((password: string) => {
    if (!password) {
      return lan.passwordInput
    }
    if (/^[a-zA-Z]+$/.test(password)) {
      return lan.passwordWarn1
    }
    if (/^[0-9]+$/.test(password)) {
      return lan.passwordWarn3
    }
    if (/^[^0-9a-zA-Z]+$/.test(password)) {
      return lan.passwordWarn2
    }
    const length = password.length
    if (length < 8 || length > 20) {
      return lan.passwordWarn4
    }
    return null
  }, [lan])

  // 注册
  const register = useCallback(async (values: any) => {
    submitToggle.update()
    try {
      const params = {
        application: "gow",
        email: values.email,
        password: values.password,
        password_confirm: values.password,
        // googlecode:"",
        // emailcode:''
      }
      await APP_API.register(params)
      message.success(lan.regisSuccessTips)
      const timer = setTimeout(()=>{
        clearTimeout(timer)
        history.push('/login')
      }, 5000)
    } catch (err: any) {
      message.error(err?.message)
    } finally {
      submitToggle.update()
    }

  }, [submitToggle, history, lan])

  // 登录
  const login = useCallback(async (values) => {
    submitToggle.update()
    try {
      const params = {
        email: values.email,
        password: values.password,
        application: "gow",
        googlecode: "",
        emailcode: ''
      }
      const { data } = await APP_API.login(params)
      if (data?.token && data?.token_expired_at * 1000 > new Date().getTime()) {
        setSsoToken(data.token)
      } else {
        setSsoToken('')
      }
      setIsGmail(false)
      message.success(lan.loginSuccessTips)
      history.push('./')
    } catch (err: any) {
      message.error(err?.message)
    } finally {
      submitToggle.update()
    }
  }, [submitToggle, setSsoToken, lan, history, setIsGmail])

  const onFinish = useCallback((values: any) => {
    pathname !== '/login' ? register(values) : login(values)
  }, [register, login, pathname])

  return (
    <div className="user" ref={scroll}>
      <div className="title">{type === 'login' ? `Metaplasia ${lan.login1}` : type === 'retrieve' ? lan.retrievePassword : `Metaplasia ${lan.Register1}`}</div>
      <Form
        form={form}
        name="register"
        className="user-form"
        onFinish={onFinish}
        scrollToFirstError
      >
        <>
          <div className="form-item-name">{lan.emailAdress}</div>
          <Form.Item
            className="phone"
            name="email"
            rules={[
              { required: true, message: lan.ePlaceholder },
              {
                // eslint-disable-next-line no-useless-escape
                pattern: /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/,
                message: lan.emailTip
              }
            ]}
          >
            <Input placeholder={lan.ePlaceholder} autoComplete="off" />
          </Form.Item>
        </>

        <>
          <div className="form-item-name">{lan.password}</div>
          <Form.Item
            name="password"
            rules={[
              () => ({
                validator(_, value) {
                  if (validateLoginPassword(value) === null) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(validateLoginPassword(value) || ''));
                },
              }),
            ]}
          >
            <Input.Password placeholder={lan.passwordWarn} />
          </Form.Item>
        </>
        <>
          <div className="tip-info">
            {pathname === '/login' && <>
            <Link to='/forgot'>{lan.forgotPassword}</Link>
            <Link to='/register'>{lan.register}</Link>
            </>}
          </div>
          <div className={`logon-box ${pathname !== '/login' ? 'block':''}`}>
          <Button loading={submitToggle.current} htmlType="submit" className="regs-btn">  {pathname === '/login' ? lan.login2 : lan.register1}</Button>
          {pathname === '/login' ? <GoogleLogin lan={lan}/> :
            <div className="to-logo-info">{lan.accountTips} <Link to='/login'>{lan.loginPage}</Link></div>}
          </div>
        </>

      </Form>
    </div>
  )
})
const mapStateToProps = ({ appStore: {
  setSsoToken,
  setIsGmail,
  lan
} }: any) => {
  return {
    setSsoToken,
    setIsGmail,
    lan: { 
      locale: lan.locale,
      ...lan.login }
  }
}

export default withRouter(inject(mapStateToProps)(memo(UserForm)))