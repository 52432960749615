import React, { useCallback, useLayoutEffect, memo } from 'react';
import { useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import { message } from 'antd';
import GEN_API from '@/api/fetch'


interface GoogleLoginProps {
  setInviteMes?: any,
  setUserToken?: any,
  lan: any
}

const GoogleLogin: React.FC<GoogleLoginProps> = observer(({ setSsoToken, lan, setIsGmail }: any) => {
  const history = useHistory()

  const initScript = () => {
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://accounts.google.com/gsi/client'
    script.onload = initGoogle
    document.head.appendChild(script)
  }

  useLayoutEffect(() => {
    initScript()
  }, [])

  const googleLogin = useCallback(async (token: string) => {
    try {
      const { data } = await GEN_API.gLogin({ token_id: token, application: "gow" })
      message.success(lan.loginSuccessTips)
      history.push('./')
      setIsGmail(data?.gmail)
      console.log('data', data)
      if (data?.token && data?.token_expired_at * 1000 > new Date().getTime()) {
        setSsoToken(data.token)
      } else {
        setSsoToken('')
      }
    } catch (err) {
      console.log('错误信息', err)
    }
  }, [setSsoToken, history, lan, setIsGmail])

  const initGoogle = useCallback(() => {
    //@ts-ignore
    if (typeof window.google !== 'undefined') {
      //@ts-ignore
      window.google?.accounts?.id.initialize({
        client_id: "559756290278-6q7qgkgeuuf2ebvcl15bpde6irpnpvis.apps.googleusercontent.com",
        login_uri: "https://metaplasia.io/#/",
        ux_mode: "popup",
        context: "signin",
        callback: function (response: any) {
          console.log("Encoded JWT ID token: " + response.credential);
          if (response.credential) {
            googleLogin(response.credential)
          }
        }
      });
      //@ts-ignore
      window.google?.accounts?.id.renderButton(
        document.getElementById("google-btn"),
        { type:'icon', theme: "outline", size: "large", shape: "rectangular", width: 430, locale:lan.locale, logo_alignment:'center'  }, // customization attributes
      );
      //@ts-ignore
      window.google?.accounts?.id.prompt(); // also display the One Tap dialog
    }
  }, [googleLogin, lan])





  return (
    <div id="google-btn" />
  );
})


const mapStateToProps = ({ appStore: {
  setSsoToken,
  setIsGmail
} }: any) => {
  return {
    setSsoToken,
    setIsGmail
  }
}

export default inject(mapStateToProps)(memo(GoogleLogin));