import React, { memo, useMemo } from "react";
import './style.scss'

const Core: React.FC<any> = memo(({lan, isLogin, goRegisPage}:any) => {

  const advantagesList = useMemo(()=>[
    {
      title:lan.sdk,
      info:lan.features
    },
    {
      title:lan.integrated,
      subTitle:lan.code,
      info:lan.integText
    },
    {
      title:lan.gateway,
      info:lan.gatewayText
    }
  ], [lan])


  return (
    <div className="core-info">
      <div className="core">
        <h1>{lan.service1}</h1>
        <h1 dangerouslySetInnerHTML={{ __html: lan.service2 }}></h1>
        <div className="sub-title">{lan.toBService}</div>
      </div>

      <div className="registered ">
        <div className={`reg-btn ${!isLogin ? '' :'disable'}`} onClick={()=>goRegisPage()}>{isLogin ? lan.willOnline :lan.regisText}</div>
        <div className="text">{`< ${lan.metaverse} >`}</div>
      </div>
      <ul className="advantages">
        {advantagesList.map((item:any)=>{
          return <li key={item.title}>
            <p >{item.title}</p>
            {item.subTitle && <div>{item.subTitle}</div>}
            <span>{item.info}</span>
          </li>
        })}
      </ul>
    </div>
  )
})


export default Core