import { useState, useCallback, useMemo } from 'react'

// export const useParams = (initial = {}) => {
//   const [values, setParams] = useState<Gow.ListParams>(initial)
//   const update = useCallback((values: Gow.ListParams) => {
//     setParams(prevState => {
//       return { ...prevState, ...values }
//     })
//   }, [])
//   return useMemo(() => ({
//     update,
//     values
//   }), [update, values])
// }

export const useToggle = () => {
  const [current, setToggle] = useState<boolean>(false)
  const update = useCallback(() => {
    setToggle(prevState => !prevState)
  }, [])
  return useMemo(() => ({
    update,
    current
  }), [update, current])
}