import API from '../API'
const API_API = new API()

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  register: (data: object) => API_API.post('/auth/register', data), // 注册账号

  login: (data: object) => API_API.post('/auth/login', data), // 账号登录账号

  gLogin: (data: object) => API_API.post('/auth/googleLogin', data), // Google登录账号

  forgetPassword: (data: object) => API_API.post('/auth/forgetPassword', data),  // 忘记密码

  retrievePassword: (data: object) => API_API.post('/auth/changePassword', data),  // 忘记密码

  applyTicket: (data: object) => API_API.post('/auth/applyTicket', data),  // 验证解析

  parseTicket: (data: object) => API_API.post('/auth/parseTicket', data),  // 解析解析
  
  cancel: () => API_API.cancel()
}
