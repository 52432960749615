import React, { memo } from "react";
import './style.scss'

const Solution: React.FC<any> = memo(({lan}:any) => {

  return(
   <div className="solution-box">
     <div className="introduction">
       <p>{lan.solution}</p>
       <span>{lan.joinMetaverse}</span>
     </div>
     <div className="box">
        <img src={require(`@/assets/images/solution-${lan.locale}.png`)} alt="" />
     </div>
 
   </div>
  )

})


export default Solution