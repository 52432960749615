import React, { memo, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import './style.scss'



  const Footer: React.FC<any> = observer(({ location:{pathname}, lan }) => {
  const navList = useMemo(() => [
    {
      title: lan.product,
      children: [
        {
          label: lan.product1
        },
        {
          label: lan.product2
        },
        {
          label: lan.product3
        },
        {
          label: lan.product4
        },
      ]
    },
    {
      title: lan.helpFile,
      children: [
        { label: lan.helpFile1 },
        // { label: `${lan.helpFile2}：support@metaplasia.io` },
      ]
    },
  ], [lan])

  const iconLists = useMemo(()=>[
    // 
    {
      icon:require('@/assets/images/footer-telegram.png'),
      url:'https://t.me/metaplasia_io'
    },
    {
      icon:require('@/assets/images/footer-twitter.png'),
      url:'https://twitter.com/lianyanshe?s=11'
    },
    {
      icon:require('@/assets/images/footer-git.png'),
      url:'https://github.com/metaplasiateam'
    },
  ], [])

  return (
    <footer className={`footer ${pathname !== '/' ? 'extra' : ' ' }`}>
      <div className="footer-layout">
        <ul className="footer-nav">
          {
            navList.map(({ title, children }: any, index: number) => {
              return (
                <li key={title}>
                  <p>{title}</p>
                  <div>
                    {
                      children.map(({ label }: any) => {
                        return(
                        <div key={label}>
                        {
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          label?.includes('https') ? <a href={label.split('twitter：')[1]} target="_blank" rel="noopener noreferrer">{label}</a> :<p>{label}</p>
                        }
                        </div>)
                      })
                    }
                  </div>
                </li>
              )
            })
          }
        </ul>
        <div className="logo-text">
        <img className="logo" src={require('@/assets/images/logo.png')} alt="" />
        <p>{lan.helpFile3}</p>
        <div style={{'height':'25px'}}></div>
        <div style={{'height':'25px'}}></div>
        <div className="icons">
        {
          iconLists.map(({icon, url}:any)=>{
             return <a href={url} key={icon} target="_blank" rel="noopener noreferrer">
             <img src={icon} className="footer-icon" alt="" />
             </a> 
          })
        }
        </div>
      </div>
      </div>
      <div className="copyright">Copyright © 2022 Metaplasia.io  All rights reserved.</div>
    </footer>
  )
})

const mapStateToProps = ({ appStore: {
  lan,
} }: any) => {
  return {
    lan:{...lan.home}
  }
}

export default withRouter(inject(mapStateToProps)(memo(Footer)))