import * as pages from '@/pages'
import { RouteProps } from 'react-router-dom'

const routes: RouteProps[] = [
  {
    path: '/',
    component: pages.Home,
  },
  {
    path: '/product',
    component: pages.Product,
  },
  {
    path: '/register',
    component: pages.Register
  },
  {
    path: '/login',
    component: pages.Login
  },
  // 忘记密码
  {
    path: '/forgot',
    component: pages.Forgot
  },
  {
    path: '/forgotPassWord',
    component: pages.ForPassword
  },
  {
    path: '*',
    component: pages.Home,
  }
]
export default routes