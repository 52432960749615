export class AnimationScrollTop {
  constructor (position, delay = 200, speed = 10) {
    this.position = position
    this.delay = delay
    this.speed = speed
    this.step = this.delay / this.speed
    this.dimension = this.position.y / this.step
    this.thisTop = window.pageYOffset
    this.delayt = this.thisTop
    this.upOrDown = this.thisTop > this.position.y
    this.delays = null
    // 初始化
    this.init()
  }
  init () {
    this.delays = setInterval(() => {
      if (!this.upOrDown) {
        if (this.delayt >= this.position.y) {
          clearInterval(this.delays)
        }
        this.delayt += this.dimension
      } else {
        if (this.delayt <= this.position.y) {
          clearInterval(this.delays)
        }
        this.delayt -= this.dimension
      }
      window.scrollTo(this.position.x, this.delayt)
    }, this.speed)
  }
}