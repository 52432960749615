import React, { memo, useMemo, useEffect, useCallback } from "react";
import { useVisibilityObserver } from 'react-visibility-observer'
import './style.scss'

const Product: React.FC<any> = memo(({ lan }: any) => {
  const { isVisible } = useVisibilityObserver()

  const prductList = useMemo(() => [
    {
      src: require('@/assets/images/product/multichain.png'),
      title: lan.support1,
      subTitle: lan.support1Text
    },
    {
      src: require('@/assets/images/product/aggregate.png'),
      title: lan.support2,
      subTitle: lan.support2Text
    },
    {
      src: require('@/assets/images/product/strategy.png'),
      title: lan.support3,
      subTitle: lan.support3Text
    },
    {
      src: require('@/assets/images/product/moreProduct.png'),
      title: lan.support4,
      subTitle: lan.support4Text
    },
  ], [lan])

  const painting = useCallback((c: any, ctx: any) => {
    let w = c.width = window.outerWidth;
    let h = c.height = 1228;
    const draw = function (a: any, b: any, t: any) {
      ctx.lineWidth = 0.3;
      ctx.fillStyle = '#00070F';
      ctx.fillRect(0, 0, w, h);
      for (let i = w / 6; i < w / 2; i += 15) {
        ctx.strokeStyle = '#FF5E44';
        ctx.beginPath();
        ctx.moveTo(0, i);
        for (let j = 1; j < 3 * h; j += 3) {
          const tplX = (10 * Math.sin(i / 10) + j + 0.01 * j * j) - 150;
          const tplY = Math.floor(j / 2 * Math.sin(j / 50 - t / 50 - i / 118) + (i * 0.9));
          ctx.lineTo(tplX, tplY);
        }
        ctx.stroke();
      }
    };
    let t = 0;

    window.addEventListener('resize', function () {
      c.width = w = window.innerWidth;
      c.height = h = window.innerHeight;
      ctx.fillStyle = 'hsla(277, 95%, 55%, 1)';
    }, false);

    const run = function () {
      window.requestAnimationFrame(run);
      t += 1;
      draw(0, 0, t);
    };
    run();
  }, [])

  useEffect(() => {
    const c = document.getElementById('canvas-01') as HTMLCanvasElement;
    const ctx: any = c.getContext('2d');
    if (isVisible) {
      painting(c, ctx)
    } else {
      ctx.clearRect(0, 0, c.width, c.height);
    }
  }, [isVisible, painting])

  return (
    <div className="product-canvas">
      <canvas id='canvas-01' />
      <img className='product-start' src={require(`@/assets/images/star.png`)} alt='' />
      <div className='product-box'>
        <div className="title">
          <p>{lan.ourProducts}</p>
          <span>{lan.merit}</span>
        </div>
        <div className="box">
          {prductList.map((item: any) => {
            return (<div key={item.title}>
              <img src={item.src} className={lan.locale ==='en-US' ? 'en':"" } alt="" />
              <p>{item.title}</p>
              <span dangerouslySetInnerHTML={{ __html: item.subTitle }} />
            </div>)
          })}
        </div>
      </div>
    </div>

  )

})


export default Product